import {CountryDropdown, RegionDropdown} from 'react-country-region-selector'
import React, {useEffect, useState} from 'react'

import CheckoutLayout from '../../components/gallery/checkout/CheckoutLayout'
import FormArrow from '../../images/svgs/formarrow.svg'
import {navigate} from 'gatsby'
import updateAction from '../../components/gallery/checkout/updateAction'
import {useForm} from 'react-hook-form'
import {useStateMachine} from 'little-state-machine'

const Address = (props) => {
  const {state, action} = useStateMachine(updateAction)
  const [region, setRegion] = useState('')
  const [country, setCountry] = useState('')
  const {register, handleSubmit, watch, errors} = useForm({
    defaultValues: state.data,
  })

  const onSubmit = (data) => {
    // use selected country to calculate flat shipping cost
    action(data)
    navigate('/checkout/payment')
  }
  // update litle-state-machine with country value so shipping calculation renders
  useEffect(() => {
    const watchCountry = watch('country')
    setRegion(state.data.region)
    action({country: watchCountry})
    console.log(JSON.stringify(state, null, 2))
  }, [country])

  return (
    <CheckoutLayout>
      <div className="flex  flex-wrap">
        <form className="w-full max-w-lg" onSubmit={handleSubmit(onSubmit)}>
          <div className="font-sans ">
            <div className="w-full">
              <label className="block tracking-wide text-gray-700 text-xs mb-2">
                Address line 1
              </label>
              <input
                ref={register}
                className="outline-none  w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:bg-black focus:text-white"
                id="address1"
                name="address1"
                type="text"></input>
              <label className="block tracking-wide text-gray-700 text-xs mb-2">
                Address line 2
              </label>
              <input
                ref={register}
                className="outline-none  w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:bg-black focus:text-white"
                id="address2"
                name="address2"
                type="text"></input>
              <label className="block  tracking-wide text-gray-700 text-xs mb-2">City*</label>
              <input
                ref={register}
                className="outline-none  w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:bg-black focus:text-white"
                id="city"
                name="city"
                type="text"
                required></input>

              <label className="block  tracking-wide text-gray-700 text-xs mb-2">Region</label>
              <RegionDropdown
                classes="outline-none  w-full text-gray-700 rounded py-3 px-4 mb-3 leading-tight  "
                country={country ? country : 'United Kingdom'}
                value={region}
                onChange={(val) => setRegion(val)}
              />
              <input type="hidden" ref={register({required: true})} name="region" value={region} />
              <label className="block  tracking-wide text-gray-700 text-xs mb-2">Country</label>
              <CountryDropdown
                showDefaultOption={true}
                defaultOptionLabel="United Kingdom"
                classes="outline-none  w-full text-gray-700 rounded py-3 px-4 mb-3 leading-tight "
                id="countrydropdown"
                name="countrydropdown"
                value={country ? country : 'United Kingdom'}
                onChange={(val) => setCountry(val)}
              />
              <input
                type="hidden"
                ref={register({required: true})}
                name="country"
                value={country ? country : 'United Kingdom'}
              />
              <label className="block tracking-wide text-gray-700 text-xs mb-2">Postal Code*</label>
              <input
                ref={register({required: true})}
                className="outline-none  w-full bg-gray-200 text-gray-700 mb-6 rounded py-3 px-4 leading-tight focus:bg-black focus:text-white"
                id="postal_code"
                name="postal_code"
                placeholder="Postal / Zip Code"
                type="text"
                required></input>
              <button className="text-white flex w-auto bg-gray p-2 rounded ">
                <FormArrow className="w-3 self-center" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </CheckoutLayout>
  )
}

export default Address
